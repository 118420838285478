import React from "react";
import style from "../../styles/slider.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import dchess from "../../images/logo_dchess.svg";
import environtek from "../../images/logo_environtek.png";
import protiva from "../../images/logo_protiva.png";
import taxon from "../../images/logo_taxon.png";
import veraggio from "../../images/logo_veraggio.png"

const swiperElement = (link, image) => {
  return (
    <SwiperSlide>
      <a href={link ? link : null} target="_blank" id="hovereable">
        <div className={style.swiperSlide} id="hovereable">
          <img src={image} alt={`logo-${image}`} id="hovereable" />
        </div>
      </a>
    </SwiperSlide>
  );
};

export default function Slider() {
  const companies = [
    { link: "https://dchess.net/", image: dchess },
    { link: "", image: environtek },
    { link: "https://taxon.tech/", image: taxon },
    { link: "https://www.instagram.com/veraggio_", image: veraggio },

    { link: "https://dchess.net/", image: dchess },
    { link: "", image: environtek },
    { link: "https://taxon.tech/", image: taxon },
    { link: "https://www.instagram.com/veraggio_", image: veraggio },
  ];

  return (
    <div className={style.container}>
      <div className={style.titleContainer}>
        <div className={style.title}>
          <h2>DELSUD forma parte de</h2>
        </div>
      </div>
      <div className={style.swiperContainer}>
        <Swiper
          breakpoints={{
            320: {
              width: 320,
              slidesPerView: 2,
              spaceBetween: 10,
            },
            480: {
              width: 480,
              slidesPerView: 3,
              spaceBetween: 30,
            },
            768: {
              width: 768,
              slidesPerView: 3.3,
              spaceBetween: 40,
            },
            900: {
              slidesPerView: 2.8,
              spaceBetween: 10,
            },
            1100: {
              slidesPerView: 3.2,
              spaceBetween: 10,
            },
            1300: {
              slidesPerView: 3.8,
              spaceBetween: 10,
            },
            1500: {
              slidesPerView: 4.5,
              spaceBetween: 10,
            },
            1800: {
              slidesPerView: 5.4,
              spaceBetween: 10,
            },
          }}
          loop={true}
        >
          {companies.map((company) =>
            swiperElement(company.link, company.image)
          )}
        </Swiper>
      </div>
    </div>
  );
}
