import React from "react";
import style from "../../styles/companies.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import desarrollosDelsud from "../../images/logo_desarrollos.png";
import elemental from "../../images/logo_elemental.svg";
import elal from "../../images/logo_elal.png";
import black from "../../images/logo_black.png";
import sacra from "../../images/logo_sacra.png";
import merci from "../../images/logo_merci.svg";
import flexy from "../../images/logo_flexy.png";
import trustFund from "../../images/logo_trustfund.svg";
import elEditor from "../../images/logo_editor.svg";

const swiperElement = (link, image) => {
  return (
    <SwiperSlide>
      <a
        href={link ? link : null}
        target={link ? `_blank` : null}
        id="hovereable"
      >
        <div className={style.swiperSlide} id="hovereable">
          <img src={image} alt={`logo-${image}`} id="hovereable" />
        </div>
      </a>
    </SwiperSlide>
  );
};

export default function Companies() {
  const companies = [
    { link: "https://desarrollosdelsud.com.ar/", image: desarrollosDelsud },
    { link: "https://elementalconstructora.com.ar/", image: elemental },
    { link: "https://elalventure.com/", image: elal },
    { link: "https://blackwallet.com.ar/", image: black },
    { link: "https://mercibroker.com.ar/", image: merci },
    { image: sacra },
    { link: "https://flexy.com.ar/", image: flexy },
    { link: "https://www.trustfund.com.ar/", image: trustFund },
    { link: "https://eleditorplatense.com/", image: elEditor },
  ];

  return (
    <div className={style.container}>
      <div className={style.titleContainer}>
        <div className={style.title}>
          <h2>Unidades de Negocios</h2>
        </div>
      </div>
      <div className={style.swiperContainer}>
        <Swiper
          breakpoints={{
            320: {
              width: 320,
              slidesPerView: 2,
              spaceBetween: 10,
            },
            480: {
              width: 480,
              slidesPerView: 3,
              spaceBetween: 30,
            },
            640: {
              slidesPerView: 4.3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3.3,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 4.3,
              spaceBetween: 20,
            },
            1800: {
              slidesPerView: 5.3,
              spaceBetween: 20,
            },
          }}
          loop={true}
        >
          {companies.map((company) =>
            swiperElement(company.link, company.image)
          )}
        </Swiper>
      </div>
    </div>
  );
}
